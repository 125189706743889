<template>
  <div class="container">
    <img class="logo" src="@/assets/images/logo_eqafh_pic@2x.png" />
    <p class="tip">请选择您的登录身份</p>
    <div class="goRegister">
      <div class="icon">
        <img src="@/assets/images/doctor@2x.png" />
      </div>
      <div class="text" @click="goRegister">
        <span>医疗相关人士，去注册</span>
        <img src="@/assets/images/pulldown_sign_icon@2x.png" />
      </div>
    </div>
    <p class="clause">登录即代表同意<span @click="$router.push('/termsOfUse')">《使用条款》</span>和<span @click="$router.push('/privacyPolicy')">《隐私政策》</span></p>
    <div class="btnBox">
      <span class="btn goLogin" @click="goLogin">已有账号？去登录</span>
      <span class="btn staffLogin" @click="goStaffLogin">强生员工登录</span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import storage from "../../common/utils/storage";
export default {
  name: 'Entrance',
  data() {
    return {}
  },
  computed: {
    ...mapState(['userInfo']),
    sid() {
      return this.$route.query.sid;
    },
    uri() {
      return this.$route.query.uri;
    },
    key() {
      return this.$route.query.key;
    }
  },
  methods: {
    goRegister() {
      // this.$router.push('/register');
      storage.setStore('lastPath', '/register');
      storage.setStore('scope', 'snsapi_userinfo');
      this.$router.push({
          path: '/authorize',
          query: {scope: 'snsapi_userinfo'}
      })
    },
    goLogin() {
      // this.$router.push('/login');
      if(!this.userInfo) {
        storage.setStore('lastPath', '/login');
        storage.setStore('scope', 'snsapi_userinfo');
        this.$router.push({
          path: '/authorize',
          query: {scope: 'snsapi_userinfo'}
        })
      } else {
        this.$router.push('/login');
      }
    },
    goStaffLogin() {
      if(!this.userInfo) {
        storage.setStore('lastPath', '/JNJUser');
        storage.setStore('scope', 'snsapi_userinfo');
        this.$router.push({
          path: '/authorize',
          query: {scope: 'snsapi_userinfo'}
        })
      } else {
        this.$router.push('/JNJUser');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  background-color: #F8F8F8;
  padding: 12px;
  box-sizing: border-box;
}
.logo {
  width: 150px;
  display: block;
  margin: 70px auto;
}
.tip {
  font-size: 15px;
  color: #666666;
  text-align: center;;
}
.goRegister {
  display: flex;
  align-items: center;
  height: 85px;
  border-radius: 10px;
  border: 1px solid rgba(202, 0, 27, 0.5);
  background-color: #FFFFFF;
  margin: 15px 0;
  .icon {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 19px;
    img {
      width: 47px;
      height: 47px;
    }
  }
  .icon::after {
    content: "";
    width: 1px;
    height: 65px;
    background-color: rgba(202, 0, 27, 0.5);
    position: absolute;
    top: 10px;
    right: 0px;
  }
  .text {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    font-size: 16px;
    color: #CA001B;
    padding: 0 20px;
    img {
      width: 14px;
      height: 7px;
      transform: rotate(-90deg);
    }
  }
}
.clause {
  font-size: 13px;
  color: #666666;
  margin: 65px 0 15px;
  span {
    color: #CA001B;
  }
}
.btnBox {
  display: flex;
  justify-content: space-between;
  .btn {
    display: inline-block;
    width: 170px;
    height: 49px;
    line-height: 49px;
    border-radius: 4px;
    font-size: 16px;
    text-align: center;
  }
  .goLogin {
    border: 1px solid rgba(202, 0, 27, 0.5);
    box-sizing: border-box;
    color: #CA001B;
  }
  .staffLogin {
    background-color: #CA001B;
    color: #FFFFFF;
  }
}
</style>